@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../variables';

.wrapper {
  line-height: normal;

  @include media-breakpoint-up('lg') {
    background: $colour-white;
    padding: 0;
    position: relative;
    z-index: $zindex-dropdown;
  }
}

.row {
  display: flex;
  height: $links-height;
  padding: 0 ($grid-vertical-gutter * .5);
  margin-bottom: 10px;
}
