@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';

.popOver {
  display: inline-block;
}

.myWpopOverBtn {
  font-size: .5em;
  background: {
    color: $colour-white;
    image: url('../MyWaitrose/mywaitrose-icon.png');
    size: contain;
  }
  height: 20px;
  width: 27px;
  color: $colour-white;
  cursor: pointer;
}

.popoverContentright {
  z-index: 2000;

  svg {
    fill: $colour-white;
  }
}

.popoverContentright {
  svg {
    margin: 0 -1px;
    z-index: 0;
    filter: drop-shadow(-2px 0 .5px $colour-primary-grey--alpha-40);
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modal;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $colour-primary-grey--alpha-70;

  > div {
    position: relative !important;
    z-index: 1;
    margin: 0 auto;
    padding: 0 !important;
    height: 190px;
    width: 300px;
    overflow: hidden !important;
    top: 30% !important;
    box-shadow: 6px 6px 5px -3px $colour-primary-grey--alpha-40;
    left: auto !important;
    right: auto !important;

  }
}

