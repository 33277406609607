@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';
@import 'ingredients/styles/focus';

$grid-size: 8px;

.fluid {
  position: relative;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  margin: $grid-size * 4 0;

  gap: $grid-size * 3 $grid-size * 3;

  @include media-breakpoint-up('md') {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up('lg') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-up('xl') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.day {
  list-style: none;

  &::before {
    display: block;
    height: $grid-size * 4;
  }

  > a {
    height: calc(100% - $grid-size * 4);
  }
}

.monday {
  &::before {
    content: "Recipe 1";
  }
}

.tuesday {
  &::before {
    content: "Recipe 2";
  }
}

.wednesday {
  &::before {
    content: "Recipe 3";
  }
}

.thursday {
  &::before {
    content: "Recipe 4";
  }
}

.friday {
  &::before {
    content: "Recipe 5";
  }
}

.saturday {
  &::before {
    content: "Recipe 6";
  }
}

.sunday {
  &::before {
    content: "Recipe 7";
  }
}

.h1 {
  text-align: center;
  margin: $grid-size * 2 0;

  @include media-breakpoint-up('xl') {
    font-size: 28px;
  }
}

.subtitle {
  text-align: center;
  margin: 0 0 $grid-size * 4;
}

.how-it-works {
  display: flex;
  position: absolute;
  top: $grid-size * 2;
  right: $grid-size * 2;
  cursor: pointer;

  .how-it-works-button {
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background: none;
      outline: none;
    }

    span {
      padding-left: 8px;
    }
  }

  @include media-breakpoint-up('md') {
    top: 20px;
  }

  @include media-breakpoint-up('lg') {
    right: $grid-size * 4;
  }

  @include media-breakpoint-up('xl') {
    top: $grid-size * 3.5;
  }

  @include media-breakpoint-down('xs') {
    top: $grid-size * 0.5;
  }
}

.card-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  cursor: pointer;
  opacity: 0.95;
  border: 0;
  top: $grid-size * 5;
  background: $colour-primary-grey no-repeat center/16px;
  @include focus;

  &:hover {
    opacity: 0.6;
  }
}

.swap {
  left: $grid-size;
  background-image: url('../icons/background-svg/swap.svg');
}

.remove {
  right: $grid-size;
  background-image: url('../icons/background-svg/close.svg');
}

.modal {
  min-width: min(100vw, 544px);
  min-height: 90dvh;

  @include media-breakpoint-down('xs') {
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

.swap-modal {
  > section > header {
    height: 0;
    min-height: 0;
  }
}

.swap-modal-content {
  overflow: visible;
  padding: 0;
}

.modal-body {
  overflow: hidden;
}

.recipe-modal {
  width: 100%;
  max-width: 648px;
  max-height: 100dvh;
  height: 100dvh;

  @media (min-width: 648px) {
    max-height: calc(100vh - 60px);
  }
}

.recipe-modal-content {
  text-align: inherit;
  padding: 0 0 20px !important;

  > div:not(svg):first-of-type {
    padding-top: 0;
    padding-left: 16px;
    position: relative;

    > div:nth-child(2) > a > div > span:nth-child(3) {
      display: none;
    }

    > div:nth-child(2) > div:first-child img {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  > div:nth-child(2) {
    padding-top: 0;
  }

  h2 {
    font-size: 20px;
    margin: 16px 0 0;
  }

  picture {
    margin: 0 -20px 0 -16px;
    display: block;
    width: calc(100% + 16px);
    height: 210px;
  }

  > div:first-child > div:first-child {
    height: 210px;
  }
}

.empty-card {
  position: relative;

  button {
    width: 100%;
    min-height: 108px;
    color: $colour-waitrose-grey;
    background-color: $colour-scallop-grey;
    border: 1px solid $colour-oyster-grey;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include focus;

    @include media-breakpoint-up('md') {
      height: calc(100% - 32px);
      min-height: 359px;
    }

    > span {
      background: url('../icons/background-svg/circle-add.svg') no-repeat;
      padding-left: 28px;
      line-height: 22px;
    }
  }
}

.confirm-remove {
  position: relative;

  > div {
    min-height: 359px;
    background-color: $colour-primary-grey;
    color: $colour-white;
    padding: $grid-size * 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up('md') {
      height: calc(100% - 32px);
    }

    button {
      width: 100%;
      height: 40px;
      border: 1px solid $colour-white;
      background-color: inherit;
      margin: $grid-size 0;
      cursor: pointer;
      @include focus;

      &:nth-child(1) {
        margin-top: $grid-size * 3;

        &:hover {
          background-color: transparentize($colour-white, 0.90);
        }
      }

      &:nth-child(2) {
        background-color: $colour-white;
        color: $colour-primary-grey;

        &:hover {
          background-color: transparentize($colour-white, 0.15);
        }
      }
    }
  }
}

.sticky-button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  border-top: 1px $colour-oyster-grey;
  background-color: $colour-white;
  z-index: 2;
}

.tutorialTooltip {
  width: 280px;
  z-index: 1000

}

.tutorialTooltipTopStart {
  margin-left: -7px;
}

.tutorialTooltipTopEnd {
  margin-left: 7px;
}

.tutorialTooltipContentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tutorialTooltipContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.tutorialTooltipCloseButton {
  cursor: pointer;
  align-self: baseline;
}

.tutorialTooltipButton {
  min-width: 100px !important;
  margin-left: auto;
  background: none;
  border: 1px solid $colour-white;
  color: $colour-white;
}

.tutorialTooltipStep {
  align-self: end;
}

.waitrose-logo {
  display: none;
}

@media print {
  .waitrose-logo {
    display: block;
    margin: 20px auto;
    height: 31px;
    width: 100%;
  }

  // Using global styles to remove unwanted elements from print due to React Modal
  :global {
    body.meal-planner-builder-page {
      position: static !important;
      overflow: visible !important;
      transform: none !important;

      #content,
      .no-print,
      header,
      footer {
        display: none !important;
      }


      .ReactModalPortal {
        position: static !important;
        overflow: visible !important;
        transform: none !important;

        .ReactModal__Overlay {
          position: static !important;
          overflow: visible !important;
          transform: none !important;
        }

        .ReactModal__Content {
          position: static !important;
          margin: 0 auto !important;
          width: 100% !important;
          max-width: 100% !important;
          height: auto !important;
          overflow: visible !important;
          padding: 1rem 0 !important;
          transform: none !important;

          [data-testid="recipes-title"] {
            h2 {
              font-size: 30px !important;
              line-height: 42px;
            }
          }

          picture > img {
            object-fit: cover;
            height: 400px !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}





