@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';

$grid-size: 8px;
$modal-padding: $grid-size * 3;
$modal-padding-mobile: $grid-size * 2;

.section {
  position: relative;
}

.scrollable {
  overflow-y: auto;
  margin-top: 1px;
  padding: $modal-padding-mobile;
  max-height: calc(100vh - 200px); // Minus the height of the load more button (with margins) and the header

  @include media-breakpoint-up('sm') {
    max-height: calc(90vh - 95px);
    padding: $modal-padding $modal-padding 24px;
  }
}

.list {
  list-style: none;
  padding-left: 0;
}

.card {
  margin-bottom: -10px;

  > a > div {
    padding-bottom: 40px;
    text-align: left;

    @media (max-width: 430px) {
      > div:first-child {
         height: 171px;
      }
    }
  }

  @media (max-width: 375px) {
    h4 {
      font-size: 14px;
    }
  }
}

.add-recipe {
  position: relative;
  top: -56px;
  margin: 12px;
  width: calc(100% - 24px);
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 32px;
}

.load-more {
  margin-top: -2px;

  @media (min-width: 375px) {
    margin-top: 6px;
  }

  @include media-breakpoint-up('sm') {
    margin-top: 14px;
  }
}

.header {
  border-bottom: 1px solid $colour-oyster-grey;
}

.prefs-button {
  cursor: pointer;
  text-align: left;
  padding-left: $modal-padding;
  border: 0;
  display: block;
  background: url('./icons/background-svg/sliders.svg') no-repeat 0 3px;
  line-height: 21px;
  margin: 16px 24px;

  @include media-breakpoint-up('sm') {
    text-decoration: underline;
  }

  @include media-breakpoint-down('xs') {
    top: -40px;
  }

  @include media-breakpoint-up('md') {
    left: $modal-padding;
  }

  > span {
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: $colour-waitrose-green;
    border-radius: 10.5px;
    padding: 0 7px;
    margin-left: 8px;
    color: $colour-white;
  }
}
